export function AmigoSymbol({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="1442"
      height="1523"
      viewBox="0 0 1442 1523"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M698.726 299.996C441.226 299.996 322.393 355.175 314.716 575.249C314.236 588.204 324.472 599.079 337.427 599.079H459.459C470.815 599.079 480.571 590.443 482.01 579.247V475.448C482.01 454.016 499.444 436.583 520.875 436.583H819.639C841.07 436.583 858.504 454.016 858.504 475.448V572.69C858.504 589.003 858.504 605.637 815.48 628.348C800.606 637.784 782.213 645.461 760.462 652.019C759.982 652.019 759.662 652.339 759.182 652.499H758.223C724.956 662.575 684.332 670.572 637.15 679.368C420.275 720.472 300.001 769.733 300.001 975.573C300.001 1112.48 384.289 1222.84 558.621 1222.84C672.336 1222.84 758.543 1186.53 817.24 1125.59C817.24 1125.59 818.519 1124.47 818.999 1123.83C819.479 1123.35 819.799 1122.88 820.279 1122.4C861.063 1078.09 927.277 1075.05 927.277 1075.05H998.929C1011.24 1075.05 1021.32 1065.14 1021.48 1052.82C1021.48 1038.43 1021.8 1022.91 1021.8 1000.84V600.839C1021.8 375.167 878.336 299.996 698.726 299.996ZM857.704 1015.56C857.704 1043.23 836.752 1065.62 810.842 1065.62H528.712C502.802 1065.62 481.851 1043.23 481.851 1015.56V957.66C481.851 957.66 481.851 956.22 481.851 955.42C483.29 868.894 515.917 827.31 666.259 797.242C755.184 778.37 830.355 759.657 857.704 734.067V1015.56Z"
        fill="var(--surface-primary)"
      />
      <path
        d="M1061.46 340.618H1093.77V427.304H1109.28V340.618H1141.75V326.703H1061.46V340.618Z"
        fill="var(--surface-primary)"
      />
      <path
        d="M1227.32 326.703L1203.49 408.271L1179.66 326.703H1152.95V427.304H1168.78V338.059L1195.97 427.304H1211.17L1238.52 337.419V427.304H1254.03V326.703H1227.32Z"
        fill="var(--surface-primary)"
      />
    </svg>
  );
}
