import { cn } from "@repo/utils";
import Image from "next/image";
import { AmigoSymbol } from "./amigoSymbol";

interface HeadshotProps {
  className?: string;
  signupPageHeadshot: string;
}

export default function Headshot({ className, signupPageHeadshot }: HeadshotProps) {
  const defaultClassName = "h-auto max-h-[370px] max-w-[277px] rounded-[35px] shadow-2xl";

  if (!signupPageHeadshot) {
    return <AmigoSymbol className={cn(defaultClassName, className)} />;
  }

  let base64Img = "";
  if (typeof signupPageHeadshot === "string") {
    base64Img = signupPageHeadshot;
  }
  const src = `data:image/png;base64,${encodeURIComponent(base64Img)}`;
  return (
    <Image
      className={cn(defaultClassName, className)}
      src={src}
      alt="Headshot"
      aria-label="Headshot"
      width={276}
      height={370}
    />
  );
}
