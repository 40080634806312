export const GradientBackground = (props: { children: React.ReactElement }) => {
  const { children } = props;
  return (
    <div className="h-full w-full overflow-hidden bg-white p-8">
      {/* Main gradient background */}
      <div className="bg-gradient-custom from-gradient-rose via-gradient-purple/90 to-gradient-mint absolute inset-0">
        {children}
      </div>
    </div>
  );
};
