"use client";
import { LoadingSpinner } from "@/shared_components/Loading";
import { Logo } from "@/shared_components/Logo";
import { AmigoLogo } from "@/shared_components/amigoLogo";
import { Button } from "@/shared_components/button";
import { GradientBackground } from "@/shared_components/gradient-background";
import Headshot from "@/shared_components/headshot";
import { InputTextField } from "@/shared_components/inputTextField";
import { toast } from "@/shared_components/toast";
import { Roles } from "@/shared_lib/constants/roles";
import { getFirebaseAuth } from "@/shared_lib/firebase";
import { GetUserResponse } from "@/shared_lib/interfaces";
import { useAuth } from "@/shared_lib/providers/authProvider";
import apiClient from "@/shared_lib/services/ApiClient";
import { getMagicLink, getUser } from "@/shared_lib/services/userService";
import { useStore } from "@/shared_lib/store/store";
import { AUTH_ERROR_CODES_MAP } from "@/shared_lib/utils/authErrors";
import { ensureUnreachable, getRedirectLink } from "@/shared_lib/utils/utils";
import { getCookieConsent, setCookieConsent } from "app/actions/cookies";
import ConsentDialog from "consentDialog";
import {
  AuthError,
  GoogleAuthProvider,
  UserCredential,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithPopup,
} from "firebase/auth";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useRouter, useSearchParams } from "next/navigation";
import { FormEvent, useEffect, useState } from "react";

enum UserStatus {
  VerifiedUser = "VerifiedUser",
  Onboarding = "Onboarding",
}

function routeUserBasedOnStatus(status: UserStatus, router: AppRouterInstance, isAdmin: boolean) {
  switch (status) {
    case UserStatus.VerifiedUser:
      if (isAdmin) {
        router.push("/admin/home");
      } else {
        router.push("/home");
      }
      break;
    case UserStatus.Onboarding:
      router.push("/onboarding");
      break;
    default:
      ensureUnreachable(status);
  }
  // Refresh router to ensure client has access to auth cookie from middleware
  router.refresh();
}

function getUserStatus(user: { email_verified: boolean }): UserStatus {
  if (user.email_verified) {
    return UserStatus.VerifiedUser;
  } else {
    return UserStatus.Onboarding;
  }
}

async function getIdTokenWithRetry(user: any, maxAttempts = 5, initialDelay = 1000): Promise<string> {
  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      const token = await user.getIdToken();
      if (token) {
        return token;
      }
    } catch (error) {
      if (attempt === maxAttempts - 1) throw error;

      // Exponential backoff: delay = initialDelay * (2^attempt)
      const delay = initialDelay * Math.pow(2, attempt);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
  throw new Error("Failed to get ID token after multiple attempts");
}

export default function LoginPage() {
  const searchParams = useSearchParams();
  const reload = searchParams.get("reload");
  const emailAdded = searchParams.get("email") || "";
  const domainData = useStore((state) => state.domainData);
  const { setUser } = useAuth();
  const provider = new GoogleAuthProvider();
  const router = useRouter();
  const [email, setEmail] = useState<string>(emailAdded);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAuth, setLoadingAuth] = useState<boolean>(true);
  const auth = getFirebaseAuth(domainData.giTenantId);
  const orgId = domainData?.orgId;
  const [inviteSent, setInviteSent] = useState<boolean>(false);
  const [cookieConsent, setLocalCookieConsent] = useState<
    | {
        analytics: boolean;
        marketing: boolean;
        personalization: boolean;
      }
    | undefined
  >(undefined);
  const [cookieConsentLoaded, setCookieConsentLoaded] = useState<boolean>(false);

  useEffect(() => {
    getCookieConsent().then((consent) => {
      if (consent !== undefined) {
        setLocalCookieConsent(JSON.parse(consent));
      }
      setCookieConsentLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (auth) {
      setLoadingAuth(false);
    } else {
      setError("Failed to initialize Firebase authentication.");
    }
  }, [auth]);

  useEffect(() => {
    if (reload) {
      router.refresh();
    }
  }, [reload, router]);

  useEffect(() => {
    if (!loadingAuth) {
      handleLoginWithEmailLinkCallback().catch((e) => {
        setError("Error handling email link callback. " + e);
        setLoading(false);
      });
    }
  }, [loadingAuth]);

  async function firebaseLogin(
    credential: UserCredential,
    orgId: string,
  ): Promise<{ error: string } | GetUserResponse> {
    try {
      const idToken = await getIdTokenWithRetry(credential.user);
      await fetch("/api/login", {
        headers: {
          Authorization: `Bearer ${idToken}`,
          cors: "no-cors",
        },
      });

      /* Set initial token in ApiClient -- this is used to authenticate the getUser request */
      apiClient.serverIdToken = idToken;

      let user = await getUser({ orgId, role: "Admin", userId: "self", showToast: true });

      setUser(user);

      return user;
    } catch (e) {
      console.error("Firebase login failed:", e);
      throw new Error("Firebase login failed.");
    }
  }

  function checkCookieConsent() {
    if (cookieConsent === undefined) {
      toast({
        title: "Cookies are required",
        message: "Please accept cookies to continue.",
        type: "error",
      });
      return false;
    }
    return true;
  }

  async function handleLoginWithEmailLink(event: FormEvent) {
    if (!checkCookieConsent()) {
      event.preventDefault();
      return;
    }

    event.preventDefault();
    setError("");
    setLoading(true);
    setInviteSent(true);

    if (!email) {
      setError("Email is required.");
      setLoading(false);
      return;
    }

    window.localStorage.setItem("emailForSignIn", email);
    const isDevelopment = process.env.NODE_ENV === "development";

    const encodedEmail = encodeURIComponent(email);

    const hostName = window.location.hostname;
    const redirectLink = getRedirectLink(hostName, orgId, isDevelopment, encodedEmail);

    try {
      const payload = {
        email,
        redirect_link: redirectLink,
      };

      await getMagicLink(orgId, Roles.ADMIN, payload);
    } catch (e) {
      console.error("Failed to get magic link:", e);
      setError("Failed to send magic link. Please try again.");
      setLoading(false);
    }
  }

  async function handleLoginWithEmailLinkCallback() {
    try {
      if (!isSignInWithEmailLink(auth, window.location.href)) {
        return;
      }
      setLoading(true);

      let callbackEmail = email || window.localStorage.getItem("emailForSignIn");

      if (!callbackEmail) {
        setError("Email for sign-in is missing.");
        setLoading(false);
        return;
      }

      const result = await signInWithEmailLink(auth, callbackEmail, window.location.href);
      const user = await firebaseLogin(result, orgId);

      if ("error" in user) {
        setError(user.error);
        setLoading(false);
        return;
      }

      const idToken = await result.user.getIdToken();

      window.localStorage.removeItem("emailForSignIn");

      if (!idToken) {
        throw new Error("No id token found");
      }

      routeUserBasedOnStatus(getUserStatus(user), router, user.is_admin);
    } catch (error) {
      console.error("Error in email link callback:", error);
      setError("Failed to complete login with email link. Please try again.");
      setLoading(false);
    }
  }

  const handleGoogleLogin = async () => {
    if (!checkCookieConsent()) {
      return;
    }

    setLoading(true);
    try {
      provider.setCustomParameters({ prompt: "select_account" });
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      const user = await firebaseLogin(result, orgId);

      if ("error" in user) {
        setError(user.error);
        setLoading(false);
        return;
      }

      if (!idToken) {
        throw new Error("No id token found");
      }

      routeUserBasedOnStatus(getUserStatus(user), router, user.is_admin);
    } catch (error) {
      const authError = error as AuthError;
      if (authError.code in AUTH_ERROR_CODES_MAP) {
        setError(AUTH_ERROR_CODES_MAP[authError.code] || "An unknown error occurred. Please try again.");
      } else {
        setError("An unknown error occurred. Please try again.");
      }
      setLoading(false);
    }
  };

  const handleCookieConsent = async (preferences: {
    analytics: boolean;
    marketing: boolean;
    personalization: boolean;
  }) => {
    await setCookieConsent(preferences);
    setLocalCookieConsent(preferences);
  };

  if (loadingAuth) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner size={20} />
        <span className="ml-2 text-gray-900 dark:text-white">Loading...</span>
      </div>
    );
  }

  return (
    <GradientBackground>
      <>
        <main className="flex min-h-screen">
          {/* Left side - Image */}
          <div className="hidden w-1/2 bg-transparent lg:block">
            <div className="-mt-4 flex h-full flex-col items-center justify-center gap-4 ">
              <div className="flex flex-col items-center justify-center">
                <AmigoLogo className="h-10 w-auto" />
              </div>
              <Headshot signupPageHeadshot={domainData.signupPageHeadshot} />
            </div>
          </div>

          {/* Right side - Login Form */}
          <div className="flex w-full items-center justify-center p-8 lg:w-1/2">
            <div className="w-full max-w-[422px] rounded-lg bg-white shadow-md">
              <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
                {loading ? (
                  inviteSent ? (
                    <div className="flex h-full items-center justify-center">
                      <span className="text-gray-900 dark:text-white">Please check your email inbox!</span>
                    </div>
                  ) : (
                    <div className="flex h-full items-center justify-center">
                      <LoadingSpinner size={20} />
                      <span className="ml-2 text-gray-900 dark:text-white">Authenticating...</span>
                    </div>
                  )
                ) : (
                  <>
                    <Logo position="center" />

                    <Button
                      type="button"
                      variant="primary"
                      className="w-full"
                      onClick={handleGoogleLogin}
                      text="Login with Google"
                    />

                    <div className="flex items-center justify-center space-x-2">
                      <hr className="w-1/3 border-gray-300 dark:border-gray-600" />
                      <span className="text-gray-400 dark:text-gray-500">or</span>
                      <hr className="w-1/3 border-gray-300 dark:border-gray-600" />
                    </div>

                    <form onSubmit={handleLoginWithEmailLink} className="space-y-4 md:space-y-6" action="#">
                      <InputTextField
                        label="Your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="name@company.com"
                        required
                      />

                      {error && (
                        <div
                          className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                          role="alert"
                        >
                          <span className="block sm:inline">{error}</span>
                        </div>
                      )}
                      <Button type="submit" variant="primary" className="w-full" text="Get Magic Link" />
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </main>

        {/* Add cookie banner */}
        {cookieConsent === undefined && cookieConsentLoaded && (
          <ConsentDialog onPreferencesSaved={handleCookieConsent} />
        )}
      </>
    </GradientBackground>
  );
}
