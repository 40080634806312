export function AmigoLogo({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="5093"
      height="1797"
      viewBox="0 0 5093 1797"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2283_615)">
        <path
          d="M4131.84 300.469C3861.76 300.469 3699.68 489.589 3699.68 757.909C3699.68 1026.23 3861.76 1213.59 4130.08 1213.59C4398.4 1213.59 4560.48 1026.23 4560.48 756.149C4560.48 486.069 4398.56 300.469 4131.84 300.469ZM4131.84 1076.95C3952.96 1076.95 3871.84 936.789 3871.84 757.909C3871.84 579.029 3952.8 438.869 4131.84 438.869C4310.88 438.869 4388.32 577.269 4388.32 757.909C4388.32 938.549 4307.36 1076.95 4131.84 1076.95Z"
          fill="var(--surface-primary)"
        />
        <path
          d="M2120.32 301.438C1988.64 301.438 1900.96 368.957 1838.4 451.678H1835.04C1801.28 355.357 1727.04 301.438 1607.2 301.438C1487.36 301.438 1405.44 362.718 1358.88 425.598C1354.56 431.358 1348 434.878 1340.8 434.878H1205.76C1193.44 434.878 1183.36 444.958 1183.36 457.278V1165.28C1183.36 1177.6 1193.44 1187.68 1205.76 1187.68H1326.4C1338.72 1187.68 1348.8 1177.6 1348.8 1165.28V659.358C1348.8 529.438 1426.4 443.358 1549.6 443.358C1672.8 443.358 1699.84 520.958 1699.84 635.838V1165.44C1699.84 1177.76 1709.92 1187.84 1722.24 1187.84H1842.88C1855.2 1187.84 1865.28 1177.76 1865.28 1165.44V659.518C1865.28 529.598 1942.88 443.518 2066.08 443.518C2189.28 443.518 2216.32 521.118 2216.32 635.998V1165.6C2216.32 1177.92 2226.4 1188 2238.72 1188H2359.36C2371.68 1188 2381.76 1177.92 2381.76 1165.6V590.078C2381.92 406.077 2294.24 301.438 2120.32 301.438Z"
          fill="var(--surface-primary)"
        />
        <path
          d="M2664 326.719H2543.36C2530.99 326.719 2520.96 336.748 2520.96 349.119V1165.12C2520.96 1177.49 2530.99 1187.52 2543.36 1187.52H2664C2676.37 1187.52 2686.4 1177.49 2686.4 1165.12V349.119C2686.4 336.748 2676.37 326.719 2664 326.719Z"
          fill="var(--surface-primary)"
        />
        <path
          d="M698.88 300C441.28 300 322.4 355.2 314.72 575.36C314.24 588.32 324.48 599.2 337.44 599.2H459.52C470.88 599.2 480.64 590.56 482.08 579.36V475.52C482.08 454.08 499.52 436.64 520.96 436.64H819.84C841.28 436.64 858.72 454.08 858.72 475.52V572.8C858.72 589.12 858.72 605.76 815.68 628.48C800.8 637.92 782.4 645.6 760.64 652.16C760.16 652.16 759.84 652.48 759.36 652.64H758.4C725.12 662.72 684.48 670.72 637.28 679.52C420.32 720.64 300 769.92 300 975.84C300 1112.8 384.32 1223.2 558.72 1223.2C672.48 1223.2 758.72 1186.88 817.44 1125.92C817.44 1125.92 818.72 1124.8 819.2 1124.16C819.68 1123.68 820 1123.2 820.48 1122.72C861.28 1078.4 927.52 1075.36 927.52 1075.36H999.2C1011.52 1075.36 1021.6 1065.44 1021.76 1053.12C1021.76 1038.72 1022.08 1023.2 1022.08 1001.12V600.96C1022.08 375.2 878.56 300 698.88 300ZM857.92 1015.84C857.92 1043.52 836.96 1065.92 811.04 1065.92H528.8C502.88 1065.92 481.92 1043.52 481.92 1015.84V957.92C481.92 957.92 481.92 956.48 481.92 955.68C483.36 869.12 516 827.52 666.4 797.44C755.36 778.56 830.56 759.84 857.92 734.24V1015.84Z"
          fill="var(--surface-primary)"
        />
        <path
          d="M3580.48 427.997H3499.52C3452.8 427.997 3416.48 403.517 3398.88 388.957C3395.36 385.597 3391.52 382.077 3387.68 378.717C3387.36 378.397 3387.04 378.237 3387.04 378.237C3338.56 335.997 3269.28 301.438 3168.96 301.438C2954.56 301.438 2789.12 446.557 2789.12 723.357C2789.12 1000.16 2954.56 1143.68 3168.96 1143.68C3307.36 1143.68 3386.72 1082.88 3433.92 1013.76H3437.28V1130.24C3437.28 1292.32 3356.32 1361.44 3199.36 1361.44C3072.32 1361.44 3014.4 1312.16 2992.96 1244.64C2989.76 1234.4 2980.64 1226.88 2969.92 1226.88H2844.16C2830.56 1226.88 2820 1238.72 2822.56 1252C2849.28 1392.48 2970.24 1496.48 3199.36 1496.48C3445.76 1496.48 3602.72 1380 3602.72 1120.16V450.397C3602.72 438.077 3592.64 427.997 3580.32 427.997H3580.48ZM3435.52 740.797C3429.6 915.357 3342.72 1006.88 3199.52 1006.88C3012.96 1006.88 2940.48 845.757 2966.72 647.357C2982.24 529.117 3084.32 438.397 3203.52 439.837C3203.52 439.837 3203.68 439.837 3203.84 439.837H3395.68C3417.12 439.837 3434.56 457.277 3434.56 478.717L3435.52 740.797C3435.84 738.877 3435.52 741.757 3435.52 740.797Z"
          fill="var(--surface-primary)"
        />
        <path
          d="M4600.16 340.639H4632.64V427.359H4648.16V340.639H4680.48V326.719H4600.16V340.639Z"
          fill="var(--surface-primary)"
        />
        <path
          d="M4766.08 326.719L4742.4 408.319L4718.4 326.719H4691.84V427.359H4707.68V338.079L4734.72 427.359H4750.08L4777.44 337.439V427.359H4792.96V326.719H4766.08Z"
          fill="var(--surface-primary)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2283_615">
          <rect width="4492.96" height="1196.48" fill="white" transform="translate(300 300)" />
        </clipPath>
      </defs>
    </svg>
  );
}
