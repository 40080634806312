export const AUTH_ERROR_CODES_MAP: { [key: string]: string } = {
  "auth/admin-restricted-operation":
    "You are not authorized to access this workspace. Please contact your administrator if you believe this is a mistake.",
  "auth/argument-error": "There was an error with the arguments provided. Please try again.",
  "auth/app-not-authorized": "This app is not authorized. Please check your app settings.",
  "auth/app-not-installed": "The app is not installed. Please install the app and try again.",
  "auth/captcha-check-failed": "Captcha check failed. Please try again.",
  "auth/code-expired": "The code has expired. Please request another one from the administrator.",
  "auth/cordova-not-ready": "Cordova is not ready. Please try again later.",
  "auth/cors-unsupported": "CORS is unsupported. Please check your browser settings.",
  "auth/credential-already-in-use": "This credential is already in use. Please use a different credential.",
  "auth/custom-token-mismatch": "Custom token mismatch. Please try again.",
  "auth/requires-recent-login": "This operation requires recent login. Please log in again.",
  "auth/dependent-sdk-initialized-before-auth": "A dependent SDK was initialized before auth. Please try again.",
  "auth/dynamic-link-not-activated": "Dynamic link not activated. Please check your settings.",
  "auth/email-change-needs-verification": "Email change needs verification. Please verify your email.",
  "auth/email-already-in-use": "This email is already in use. Please use a different email.",
  "auth/emulator-config-failed": "Emulator configuration failed. Please check your settings.",
  "auth/expired-action-code": "The action code has expired. Please request a new one.",
  "auth/cancelled-popup-request": "Popup request was cancelled. Please try again.",
  "auth/internal-error": "An internal error occurred. Please try again later.",
  "auth/invalid-api-key": "The API key is invalid. Please check your settings.",
  "auth/invalid-app-credential": "Invalid app credential. Please check your app settings.",
  "auth/invalid-app-id": "Invalid app ID. Please check your app settings.",
  "auth/invalid-user-token": "Invalid user token. Please log in again.",
  "auth/invalid-auth-event": "Invalid auth event. Please try again.",
  "auth/invalid-cert-hash": "Invalid certificate hash. Please check your settings.",
  "auth/invalid-verification-code": "Invalid verification code. Please check the code and try again.",
  "auth/invalid-continue-uri": "Invalid continue URI. Please check your settings.",
  "auth/invalid-cordova-configuration": "Invalid Cordova configuration. Please check your settings.",
  "auth/invalid-custom-token": "Invalid custom token. Please try again.",
  "auth/invalid-dynamic-link-domain": "Invalid dynamic link domain. Please check your settings.",
  "auth/invalid-email": "Invalid email address. Please check the email and try again.",
  "auth/invalid-emulator-scheme": "Invalid emulator scheme. Please check your settings.",
  "auth/invalid-credential": "Invalid credential. Please check your credentials and try again.",
  "auth/invalid-message-payload": "Invalid message payload. Please try again.",
  "auth/invalid-multi-factor-session": "Invalid multi-factor session. Please try again.",
  "auth/invalid-oauth-client-id": "Invalid OAuth client ID. Please check your settings.",
  "auth/invalid-oauth-provider": "Invalid OAuth provider. Please check your settings.",
  "auth/invalid-action-code": "Invalid action code. Please request a new one.",
  "auth/unauthorized-domain": "Unauthorized domain. Please check your settings.",
  "auth/wrong-password": "Incorrect password. Please try again.",
  "auth/invalid-persistence-type": "Invalid persistence type. Please check your settings.",
  "auth/invalid-phone-number": "Invalid phone number. Please check the number and try again.",
  "auth/invalid-provider-id": "Invalid provider ID. Please check your settings.",
  "auth/invalid-recipient-email": "Invalid recipient email. Please check the email and try again.",
  "auth/invalid-sender": "Invalid sender. Please check your settings.",
  "auth/invalid-verification-id": "Invalid verification ID. Please try again.",
  "auth/invalid-tenant-id": "Invalid tenant ID. Please check your settings.",
  "auth/multi-factor-info-not-found": "Multi-factor info not found. Please try again.",
  "auth/multi-factor-auth-required": "Multi-factor authentication required. Please complete the authentication.",
  "auth/missing-android-pkg-name": "Missing Android package name. Please check your settings.",
  "auth/missing-app-credential": "Missing app credential. Please check your app settings.",
  "auth/auth-domain-config-required": "Auth domain configuration required. Please check your settings.",
  "auth/missing-verification-code": "Missing verification code. Please check the code and try again.",
  "auth/missing-continue-uri": "Missing continue URI. Please check your settings.",
  "auth/missing-iframe-start": "Missing iframe start. Please check your settings.",
  "auth/missing-ios-bundle-id": "Missing iOS bundle ID. Please check your settings.",
  "auth/missing-or-invalid-nonce": "Missing or invalid nonce. Please try again.",
  "auth/missing-multi-factor-info": "Missing multi-factor info. Please try again.",
  "auth/missing-multi-factor-session": "Missing multi-factor session. Please try again.",
  "auth/missing-phone-number": "Missing phone number. Please check the number and try again.",
  "auth/missing-verification-id": "Missing verification ID. Please try again.",
  "auth/app-deleted": "The app has been deleted. Please reinstall the app.",
  "auth/account-exists-with-different-credential":
    "An account exists with a different credential. Please use the correct credential.",
  "auth/network-request-failed": "Network request failed. Please check your internet connection.",
  "auth/null-user": "No user found. Please log in again.",
  "auth/no-auth-event": "No auth event. Please try again.",
  "auth/no-such-provider": "No such provider. Please check your settings.",
  "auth/operation-not-allowed": "This operation is not allowed. Please contact your administrator.",
  "auth/operation-not-supported-in-this-environment":
    "This operation is not supported in this environment. Please check your settings.",
  "auth/popup-blocked": "Popup was blocked. Please allow popups and try again.",
  "auth/popup-closed-by-user": "Popup was closed by the user. Please try again.",
  "auth/provider-already-linked": "Provider is already linked. Please use a different provider.",
  "auth/quota-exceeded": "Quota exceeded. Please try again later.",
  "auth/redirect-cancelled-by-user": "Redirect was cancelled by the user. Please try again.",
  "auth/redirect-operation-pending": "Redirect operation is pending. Please wait.",
  "auth/rejected-credential": "Credential was rejected. Please use a different credential.",
  "auth/second-factor-already-in-use": "Second factor is already in use. Please use a different factor.",
  "auth/maximum-second-factor-count-exceeded":
    "Maximum second factor count exceeded. Please contact your administrator.",
  "auth/tenant-id-mismatch": "Tenant ID mismatch. Please check your settings.",
  "auth/timeout": "Operation timed out. Please try again.",
  "auth/user-token-expired": "User token has expired. Please log in again.",
  "auth/too-many-requests": "Too many requests. Please try again later.",
  "auth/unauthorized-continue-uri": "Unauthorized continue URI. Please check your settings.",
  "auth/unsupported-first-factor": "Unsupported first factor. Please use a different factor.",
  "auth/unsupported-persistence-type": "Unsupported persistence type. Please check your settings.",
  "auth/unsupported-tenant-operation": "Unsupported tenant operation. Please check your settings.",
  "auth/unverified-email": "Email is unverified. Please verify your email.",
  "auth/user-cancelled": "User cancelled the operation. Please try again.",
  "auth/user-not-found": "User not found. Please check your credentials.",
  "auth/user-disabled": "User account is disabled. Please contact your administrator.",
  "auth/user-mismatch": "User mismatch. Please check your credentials.",
  "auth/user-signed-out": "User signed out. Please log in again.",
  "auth/weak-password": "Weak password. Please use a stronger password.",
  "auth/web-storage-unsupported": "Web storage is unsupported. Please check your browser settings.",
  "auth/already-initialized": "Auth is already initialized. Please try again.",
  "auth/recaptcha-not-enabled": "reCAPTCHA is not enabled. Please check your settings.",
  "auth/missing-recaptcha-token": "Missing reCAPTCHA token. Please complete the reCAPTCHA.",
  "auth/invalid-recaptcha-token": "Invalid reCAPTCHA token. Please complete the reCAPTCHA.",
  "auth/invalid-recaptcha-action": "Invalid reCAPTCHA action. Please try again.",
  "auth/missing-client-type": "Missing client type. Please check your settings.",
  "auth/missing-recaptcha-version": "Missing reCAPTCHA version. Please check your settings.",
  "auth/invalid-recaptcha-version": "Invalid reCAPTCHA version. Please check your settings.",
  "auth/invalid-req-type": "Invalid request type. Please try again.",
};
