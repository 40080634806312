import { Button } from "@/shared_components/button";
import { Typography } from "@/shared_components/typography";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Switch,
} from "@repo/ui";
import { useState } from "react";

interface ConsentDialogProps {
  onPreferencesSaved: (preferences: { analytics: boolean; marketing: boolean; personalization: boolean }) => void;
}

function ConsentDialog(props: ConsentDialogProps) {
  const { onPreferencesSaved } = props;
  const [showDialog, setShowDialog] = useState(true);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    analytics: false,
    marketing: false,
    personalization: false,
  });

  const handleSavePreferences = (preferences: { analytics: boolean; marketing: boolean; personalization: boolean }) => {
    setShowDialog(false);
    onPreferencesSaved(preferences);
  };

  const handleAcceptAll = () => {
    handleSavePreferences({
      analytics: true,
      marketing: true,
      personalization: true,
    });
  };

  const handleRejectAll = () => {
    handleSavePreferences({
      analytics: false,
      marketing: false,
      personalization: false,
    });
  };

  return (
    <div className="mx-4">
      <AlertDialog open={showDialog}>
        <AlertDialogContent className="max-w-2xl">
          <AlertDialogHeader>
            <AlertDialogTitle className="flex  items-center justify-center text-xl">
              Cookie Preferences
            </AlertDialogTitle>
            <AlertDialogDescription className="text-base">
              {!showPreferences ? (
                <div className="space-y-4">
                  <Typography variant="label" weight="500">
                    We use cookies to enhance your browsing experience, serve personalized content, and analyze our
                    traffic. Please select your preferences below.
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="label" weight="700">
                    Essential cookies are always active in order for the website to function properly.
                  </Typography>
                </div>
              ) : (
                <div className="space-y-6">
                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium">Essential Cookies</h3>
                        <p className="text-sm text-gray-500">
                          Required for basic site functionality. Cannot be disabled.
                        </p>
                      </div>
                      <Switch checked={true} disabled />
                    </div>

                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium">Analytics Cookies</h3>
                        <p className="text-sm text-gray-500">
                          Help us understand how visitors interact with our website.
                        </p>
                      </div>
                      <Switch
                        checked={preferences.analytics}
                        onCheckedChange={(checked) => setPreferences((prev) => ({ ...prev, analytics: checked }))}
                      />
                    </div>

                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium">Marketing Cookies</h3>
                        <p className="text-sm text-gray-500">Used to deliver personalized advertisements.</p>
                      </div>
                      <Switch
                        checked={preferences.marketing}
                        onCheckedChange={(checked) => setPreferences((prev) => ({ ...prev, marketing: checked }))}
                      />
                    </div>

                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium">Personalization Cookies</h3>
                        <p className="text-sm text-gray-500">
                          Remember your preferences and provide enhanced features.
                        </p>
                      </div>
                      <Switch
                        checked={preferences.personalization}
                        onCheckedChange={(checked) => setPreferences((prev) => ({ ...prev, personalization: checked }))}
                      />
                    </div>
                  </div>
                </div>
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="flex-col gap-2">
            {!showPreferences ? (
              <>
                <Button type="button" className="w-full" onClick={handleAcceptAll} text="Accept All" />
                <Button
                  type="button"
                  variant="outline"
                  className="w-full"
                  onClick={() => setShowPreferences(true)}
                  text="Customize Preferences"
                />
                <Button
                  type="button"
                  variant="outline"
                  className="w-full"
                  onClick={handleRejectAll}
                  text="Reject All"
                />
              </>
            ) : (
              <>
                <Button
                  variant="outline"
                  type="button"
                  className="w-full sm:w-auto"
                  onClick={() => setShowPreferences(false)}
                  text="Back"
                />
                <Button
                  type="button"
                  className="w-full sm:w-auto"
                  onClick={() => handleSavePreferences(preferences)}
                  text="Save Preferences"
                />
              </>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

export default ConsentDialog;
