import { Typography } from "@/shared_components/typography";
import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

export interface InputTextFieldProps {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onSubmit?: () => void;
  id?: string;
  placeholder?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  value: string;
  label?: string;
  size?: "small" | "medium" | "large" | "none";
  className?: string;
  disabled?: boolean;
  required?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
  // should only be used by inputWithSubmit
  rightIcon?: React.ReactNode;
  tabIndex?: number;
}

export function InputTextField({
  onChange,
  onSubmit,
  id,
  value,
  label,
  disabled,
  size,
  className,
  onKeyDown,
  placeholder,
  required,
  autoFocus,
  onBlur,
  rightIcon,
  tabIndex = 0,
}: InputTextFieldProps) {
  const idRef = useRef(id || uuidv4());

  const sizeClass = {
    none: "",
    small: "px-4 py-2 rounded-[12px]",
    medium: "px-4 py-3 rounded-[16px]",
    large: "px-4 py-4 rounded-[20px]",
  }[size || "medium"];

  const disabledClass = disabled
    ? "text-content-support border-border-components"
    : "bg-white border-border-components";

  const textSizeClass = {
    small: "text-xs placeholder:text-xs",
    medium: "text-base placeholder:text-base",
    large: "text-lg placeholder:text-lg",
    none: "",
  }[size || "none"];

  return (
    <div className={`${className} relative flex flex-col`}>
      <label className="text-content-primary" htmlFor={idRef.current}>
        <Typography weight="500" variant="small">
          {label}
        </Typography>
      </label>
      <input
        disabled={disabled}
        required={required}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder || ""}
        id={idRef.current}
        onChange={onChange}
        className={` ${sizeClass} shadow-button inline-flex items-center ${disabledClass} placeholder:text-content-placeholder focus:ring-content-placeholder justify-center gap-1 rounded-2xl border border-[#e9e9e9] bg-white leading-none ${textSizeClass} focus:ring-2 ${rightIcon ? "pr-12" : ""}`}
        tabIndex={tabIndex}
      />
      {onSubmit && (
        <button onClick={onSubmit} className={`absolute right-4 ${label ? "top-1/2" : "top-1/2 -translate-y-1/2"}`}>
          {rightIcon}
        </button>
      )}
    </div>
  );
}
