import { useStore } from "@/shared_lib/store/store";
import { cn } from "@repo/utils";
import Image from "next/image";
import { AmigoLogo } from "../amigoLogo";
interface LogoProps {
  altText?: string;
  overrideLogoUrl?: string;
  position?: string;
  onClick?: () => void;
  className?: string;
}

export const Logo = ({ altText, overrideLogoUrl, position, onClick, className }: LogoProps) => {
  const domainData = useStore((state) => state.domainData);
  const logo = overrideLogoUrl || domainData?.logo || "";
  const src = `data:image/png;base64,${encodeURIComponent(logo)}`;

  if (!logo) {
    return <AmigoLogo className="h-10 w-auto" />;
  }

  return (
    <div>
      <Image
        className={cn(
          "h-10 w-auto",
          className,
          position === "center" ? "mx-auto" : "mr-4",
          onClick ? "cursor-pointer" : "",
        )}
        src={src}
        onClick={onClick}
        width={0}
        height={0}
        alt={altText || "logo"}
      />
    </div>
  );
};
